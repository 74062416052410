import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Pomoc"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "pomoc-serwisu-opalcboxpl",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#pomoc-serwisu-opalcboxpl",
        "aria-label": "pomoc serwisu opalcboxpl permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pomoc serwisu `}<a parentName="h1" {...{
        "href": "https://opalcbox.pl/"
      }}>{`opalcbox.pl`}</a></h1>
    <blockquote>
      <blockquote parentName="blockquote">
        <blockquote parentName="blockquote">
          <p parentName="blockquote">{`Jeżeli na stronach pomocy nie znalazłeś potrzebnej informacji, masz wątpliwości odnośnie poprawnego działania serwisu
lub merytorycznej realizacji procesów, zarejestruj zgłoszenie service desk wysyłając e-mail na adres `}<inlineCode parentName="p">{`sd@opalcbox.pl`}</inlineCode>{`.
Odpowiemy w ciągu 24h. `}</p>
        </blockquote>
      </blockquote>
    </blockquote>
    <p>{`Używanie serwisu rozpocznij od `}<a parentName="p" {...{
        "href": "/starting/getting-started"
      }}>{`Jak rozpocząć`}</a>{` i pamiętaj że: `}</p>
    <ul>
      <li parentName="ul">{`nie ponosisz opłat za korzystanie z serwisu jeżeli żadna usługa nie jest aktywna i nie są zaznaczone płatne opcje na usłudze `}<inlineCode parentName="li">{`STANDARD`}</inlineCode>{`,`}</li>
      <li parentName="ul">{`odpowiadasz za dopełnienie wymogów formalnych UKE, decyzje podejmowane w czasie realizacji procesów oraz poprawność przekazywanych danych,`}</li>
      <li parentName="ul">{`samodzielnie kontaktujesz się z UKE, w tym w celu wyjaśnienia wątpliwości merytorycznych.`}</li>
    </ul>
    <p>{`Szczegółowy opis usług, które udostępnia serwis zawiera `}<a parentName="p" {...{
        "href": "/setup-and-configuration/service-managing"
      }}>{`Usługi`}</a>{`.`}<br parentName="p"></br>{`
`}{`Opis funkcji i sposób ich używania znajdziesz w `}<inlineCode parentName="p">{`KORZYSTANIE Z SERWISU`}</inlineCode>{` w menu po lewej stronie.  `}</p>
    <p>{`Sposób rozliczenia z serwisem znajdziesz w `}<a parentName="p" {...{
        "href": "https://opalcbox.pl/pricing/"
      }}>{`Cenniku`}</a>{`, a
szczegółowy opis warunków świadczenia usług w `}<a parentName="p" {...{
        "href": "https://opalcbox.pl/terms-of-use/"
      }}>{`Regulaminie`}</a>{`.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      